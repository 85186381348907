import React from 'react';

import { Dialog } from './Dialog';


export const DeleteFeedsDialog = ({ isOpen, onConfirm, onCancel, feedsCount }: {isOpen: boolean; onConfirm: () => void; onCancel: () => void; feedsCount: number;}) => {
  if (isOpen) {
    return <div>
      <Dialog
        id="delete-feeds"
        title={`Delete ${feedsCount} feeds?`}
        subtitle="Are you sure you want to unsubscribe and delete the selected RSS feeds? Any documents in your Feed section related to these feeds that have not been saved to your Library will also be deleted."
        actionTitle={`Delete ${feedsCount} feeds`}
        cancelTitle="Cancel"
        redActionButton
        action={onConfirm}
        cancelAction={onCancel} />
      </div>;
  }

  return null;
};
