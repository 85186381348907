import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { openSaveFilterSubMenu } from '../../../../shared/foreground/cmdPalette';
import { updateFilteredView } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { addFeedIdToQuery, removeFeedIdFromQuery } from '../../../../shared/foreground/utils/feedQueriesHelpers';
import { FilteredView, RssFeed } from '../../../../shared/types';
import ChevronDownSmall from '../icons/ChevronDownSmallIcon';
import Tag from '../Tag';
import { getSeparatorOption, getTitleOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './FeedViewsDropdown.module.css';

const Trigger = ({ associatedViews, isFocused }: {associatedViews?: FilteredView[]; isFocused: boolean;}) => {
  return (
    <>
      <div className={styles.viewsTags}>
        {associatedViews?.length ? associatedViews.map((view) => {
          return <Tag key={view.id} className={styles.viewTag}>{view.name || 'No name'}</Tag>;
        }) : <span className={styles.noViews}>Manage views...</span>}
      </div>
      {isFocused && <span className={styles.chevronWrapper}>
        <ChevronDownSmall />
      </span>}
    </>
  );
};

export default React.memo(function FeedViewsDropDown({ feedId, feed, views, associatedViews, isFocused = false }: {feedId: string; feed: RssFeed; views?: FilteredView[]; associatedViews?: FilteredView[]; isFocused: boolean;}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (window.isRadixDropdownOpen === isOpen) {
      return;
    }

    window.isRadixDropdownOpen = isOpen;

    return () => {
      window.isRadixDropdownOpen = false;
    };
  }, [isOpen]);

  const TriggerElement =
    <DropdownMenu.Trigger className={`${styles.dropdownTrigger} ${isOpen ? styles.isOpen : ''}`}>
      <Trigger associatedViews={associatedViews} isFocused={isFocused} />
    </DropdownMenu.Trigger>;

  const removeFeedFromView = useCallback((view: FilteredView) => {
    const newQuery = removeFeedIdFromQuery({ query: view.query, feedId });

    updateFilteredView({
      ...view,
      query: newQuery,
    }, { userInteraction: 'unknown' });
  }, [feedId]);

  const addFeedToView = useCallback((view: FilteredView) => {
    const newQuery = addFeedIdToQuery({ query: view.query, feedId });

    updateFilteredView({
      ...view,
      query: newQuery,
    }, { userInteraction: 'unknown' });
  }, [feedId]);

  const viewsOptions = useMemo(() => views?.map((view) => {
    const checked = Boolean(associatedViews?.find((_view) => _view.id === view.id));

    return {
      type: DropdownOptionType.Checkbox,
      name: view.name || 'No name',
      checked,
      setChecked: (isChecked: boolean) => {
        if (isChecked) {
          addFeedToView(view);
        } else {
          removeFeedFromView(view);
        }
      },
    };
  }) ?? [], [associatedViews, views, addFeedToView, removeFeedFromView]);

  const options = useMemo(() => [
    getTitleOption(`Include documents from “${feed.name}” in the following views`, styles.itemTitle),
    getSeparatorOption(),
    {
      type: DropdownOptionType.ScrollableItems,
      childOptions: viewsOptions,
    },
    getSeparatorOption(),
    {
      type: DropdownOptionType.Item,
      isCreate: true,
      name: 'Create a new view from feed',
      onSelect: () => {
        openSaveFilterSubMenu();
      },
    },
  ], [feed.name, viewsOptions]);

  // Appending a lot of dropdowns to the body makes the transition to this view
  // a bit slow. So we are going to append it only after the user actually clicks it.

  if (isOpen) {
    return (
      <Dropdown
        trigger={TriggerElement}
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        sideOffset={10}
        appendToDocumentBody
      />
    );
  }

  return (
    <button type="button" className={styles.dropdownTrigger} onClick={() => setIsOpen(true)}>
      <Trigger associatedViews={associatedViews} isFocused={isFocused} />
    </button>
  );
});
