import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useMemo, useState } from 'react';

import { openSaveFilterSubMenu } from '../../../../shared/foreground/cmdPalette';
import { updateFilteredView } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { setFilterQueryToCreate } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/filtereredView';
import { addFeedIdToQuery, removeFeedIdFromQuery } from '../../../../shared/foreground/utils/feedQueriesHelpers';
import { FilteredView } from '../../../../shared/types';
import Button from '../Button';
import ViewsIcon from '../icons/16StrokeViews';
import ChevronDownIcon from '../icons/ChevronDownSmallIcon';
import styles from './BulkFeedsViewsDropdown.module.css';
import { getSeparatorOption, getTitleOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';

const Trigger = () => {
  return (
    <Button className={styles.manageViewsButton} variant="secondary">
      <ViewsIcon className={styles.viewsIcon} />
      Manage views
      <ChevronDownIcon />
    </Button>
  );
};

export default React.memo(function BulkFeedsViewsDropdown({ selectedFeedIds, views, associatedViews }: {selectedFeedIds: string[]; views: FilteredView[]; associatedViews: (FilteredView & { isAssociatedWithAllFeeds: boolean; })[];}) {
  const [isOpen, setIsOpen] = useState(false);

  const TriggerElement =
    <DropdownMenu.Trigger>
      <Trigger />
    </DropdownMenu.Trigger>;

  const removeFeedFromView = useCallback((view: FilteredView) => {
    let newQuery = view.query;

    for (const feedId of selectedFeedIds) {
      newQuery = removeFeedIdFromQuery({ query: newQuery, feedId });
    }

    updateFilteredView({
      ...view,
      query: newQuery,
    }, { userInteraction: 'unknown' });
  }, [selectedFeedIds]);

  const addFeedToView = useCallback((view: FilteredView) => {
    let newQuery = view.query;

    for (const feedId of selectedFeedIds) {
      newQuery = addFeedIdToQuery({ query: newQuery, feedId });
    }

    updateFilteredView({
      ...view,
      query: newQuery,
    }, { userInteraction: 'unknown' });
  }, [selectedFeedIds]);

  const viewsOptions = useMemo(() => views?.map((view) => {
    const associatedView = associatedViews?.find((_view) => _view.id === view.id);
    const checked = Boolean(associatedView);

    return {
      type: DropdownOptionType.Checkbox,
      name: view.name || 'No name',
      checked,
      isMinusIcon: !associatedView?.isAssociatedWithAllFeeds,
      setChecked: (isChecked: boolean) => {
        if (isChecked) {
          addFeedToView(view);
        } else {
          removeFeedFromView(view);
        }
      },
    };
  }) ?? [], [associatedViews, views, addFeedToView, removeFeedFromView]);

  const options = useMemo(() => [
    getTitleOption(`Include documents from ${selectedFeedIds.length} selected feeds in the following views`, styles.itemTitle),
    getSeparatorOption(),
    {
      type: DropdownOptionType.ScrollableItems,
      childOptions: viewsOptions,
    },
    getSeparatorOption(),
    {
      type: DropdownOptionType.Item,
      isCreate: true,
      name: 'Create a new view from selected feeds',
      className: styles.createViewOption,
      onSelect: () => {
        const queryToCreate = selectedFeedIds.map((feedId) => `rssSource:${feedId}`).join(' OR ');
        setFilterQueryToCreate(queryToCreate);
        openSaveFilterSubMenu();
      },
    },
  ], [selectedFeedIds, viewsOptions]);

  return (
    <Dropdown
      trigger={TriggerElement}
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      appendToDocumentBody
    />
  );
});
