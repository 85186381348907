import { removeSubQueryFromQuery } from '../../filters-compiler/removeSubQueryFromQuery';

export const addFeedIdToQuery = ({ query, feedId }: {query: string; feedId: string;}) => {
  return `(${query}) OR rssSource:"${feedId}"`;
};

export const removeFeedIdFromQuery = ({ query, feedId }: {query: string; feedId: string;}) => {
  let newQuery = query;

  if (query.includes(`rssSource:"${feedId}"`)) {
    newQuery = removeSubQueryFromQuery({ subQuery: `rssSource:"${feedId}"`, query });
  } else if (query.includes(`rssSource:${feedId}`)) {
    newQuery = removeSubQueryFromQuery({ subQuery: `rssSource:${feedId}`, query });
  } else if (query.includes(`rss_source:"${feedId}"`)) {
    newQuery = removeSubQueryFromQuery({ subQuery: `rss_source:"${feedId}"`, query });
  } else if (query) {
    newQuery = removeSubQueryFromQuery({ subQuery: `rss_source:${feedId}`, query });
  }

  return newQuery;
};
