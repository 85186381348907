import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { openEditFeedSubMenu, openFeedsSubMenu } from '../../../shared/foreground/cmdPalette';
import { globalState } from '../../../shared/foreground/models';
import { useFeedsStats, useSavedFilteredViews, useViewsByFeedId } from '../../../shared/foreground/stateHooks';
import { setSortFeedsByKey, setSortFeedsByOrder } from '../../../shared/foreground/stateUpdaters/clientStateUpdaters/sortManagement';
import { removeFeeds } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/bulk';
import { removeFeed } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { setFocusedDocumentId, setFocusedFeedId } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { createToast } from '../../../shared/foreground/toasts.platform';
import { copyTextToClipboard } from '../../../shared/foreground/utils/copyTextToClipboard';
import getSplitByDefaultValue from '../../../shared/foreground/utils/getSplitByDefaultValue';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import useStatePlusLiveValueRef from '../../../shared/foreground/utils/useStatePlusLiveValueRef';
import {
  FeedStats,
  FilteredView,
  RssFeed,
  ShortcutId,
  SortOrder,
  SplitByKey,
  TableHeader,
  TableSortKey,
} from '../../../shared/types';
import getFormattedDurationFromNow from '../../../shared/utils/dates/getFormattedDurationFromNow';
import parseStringTimestamp from '../../../shared/utils/dates/parseStringTimestamp';
import urlJoin from '../../../shared/utils/urlJoin';
import useDebounce from '../../../shared/utils/useDebounce';
import { useHotKeysPreventDefault } from '../hooks/hooks';
import useOnItemChecked from '../hooks/useOnItemChecked';
import useScrollIntoViewIfNeeded from '../hooks/useScrollIntoViewIfNeeded';
import { reactLazy } from '../utils/dynamicImport';
import { useShortcutsMap } from '../utils/shortcuts';
import BulkActionsHeader from './BulkActionsHeader';
import Button from './Button';
import { CustomCheckbox } from './Checkbox';
import { DeleteFeedDialog } from './DeleteFeedDialog';
import { DeleteFeedsDialog } from './DeleteFeedsDialog';
import BulkFeedsViewsDropdown from './Dropdown/BulkFeedsViewsDropdown';
import FeedViewsDropDown from './Dropdown/FeedViewsDropdown';
import styles from './FeedsSourceList.module.css';
import { FloatingPill } from './FloatingPill';
import TrashIcon from './icons/16StrokeTrash';
import FeedFallbackFavicon from './icons/FeedFallbackFavicon';
import StrokeIcon from './icons/StrokeIcon';
import { ImageWithFallback } from './ImageWithFallback';
import LastUpdatedOrActionButtons, { DeleteButton, EditButton } from './LastUpdatedOrActionButtons';
import SearchInput from './SearchInput';
import { Table } from './Table';
import Tooltip from './Tooltip';

const InboxSidebar = reactLazy(() => import('./InboxSidebar'));

const FeedsBulkActionsHeader = React.memo(function FeedsBulkActionsHeader({ onCheckedChange, isChecked, setSelectedFeedIds, selectedFeedIds, areAllItemsSelected = false, viewsByFeedId, views }: { onCheckedChange: () => void; isChecked: boolean; setSelectedFeedIds: (v: string[]) => void; selectedFeedIds: string[]; areAllItemsSelected: boolean; views: FilteredView[]; viewsByFeedId: { [feedId: string]: FilteredView[];};}) {
  const [deleteFeedsDialogOpen, setDeleteFeedsDialogOpen] = useState(false);

  const isViewAssociatedWithAllFeeds = useCallback((viewId: string) => {
    let isAssociatedWithAllFeeds = true;

    for (const feedId of selectedFeedIds) {
      const views = viewsByFeedId[feedId];
      const isAssociatedWithFeed = views && views.some((view) => view.id === viewId);
      if (!isAssociatedWithFeed) {
        isAssociatedWithAllFeeds = false;
        break;
      }
    }

    return isAssociatedWithAllFeeds;
  }, [selectedFeedIds, viewsByFeedId]);

  const associatedViews: (FilteredView & { isAssociatedWithAllFeeds: boolean; })[] = useMemo(() => {
    const allViews: (FilteredView & { isAssociatedWithAllFeeds?: boolean; })[] = [];

    selectedFeedIds.forEach((feedId) => {
      if (viewsByFeedId[feedId]) {
        allViews.push(...viewsByFeedId[feedId]);
      }
    });

    return uniqBy(allViews, 'id').map((view) => {
      const isAssociatedWithAllFeeds = isViewAssociatedWithAllFeeds(view.id);

      return {
        ...view,
        isAssociatedWithAllFeeds,
      } as FilteredView & { isAssociatedWithAllFeeds: boolean; };

    });
  }, [selectedFeedIds, viewsByFeedId, isViewAssociatedWithAllFeeds]);

  return (
    <>
      <BulkActionsHeader
        selectedIds={selectedFeedIds}
        setSelectedIds={setSelectedFeedIds}
        resourceName="Feed"
        onCheckedChange={onCheckedChange}
        isChecked={isChecked}
        isMinusIcon={!areAllItemsSelected}
      >
        <BulkFeedsViewsDropdown
          selectedFeedIds={selectedFeedIds}
          views={views}
          associatedViews={associatedViews}
        />

        <Button className={styles.deleteButton} variant="secondary" onClick={() => setDeleteFeedsDialogOpen(true)}>
          <TrashIcon /> Delete
        </Button>
      </BulkActionsHeader>

      <DeleteFeedsDialog
        isOpen={deleteFeedsDialogOpen}
        onConfirm={() => {
          removeFeeds(selectedFeedIds, { userInteraction: 'click' });
          setSelectedFeedIds([]);
        }}
        feedsCount={selectedFeedIds.length}
        onCancel={() => setDeleteFeedsDialogOpen(false)}
      />
    </>
  );
});

const FeedLastUpdatedOrActionButtons = React.memo(function _FeedLastUpdatedOrActionButtonsLastUpdatedOrActionButtons({ id, lastUpdated, isFocused, url, deleteShortcut, onDelete, areSelectedItems }: { id: string; lastUpdated?: number | string; isFocused: boolean; url: string; deleteShortcut: string | string[]; onDelete: (id: string) => void; areSelectedItems: boolean; }) {
  const timestamp = useMemo(() => typeof lastUpdated === 'string' ? parseInt(lastUpdated, 10) : lastUpdated, [lastUpdated]);
  const lastUpdatedFromNow = useMemo(() => timestamp ? getFormattedDurationFromNow(parseStringTimestamp(timestamp)) : '-', [timestamp]);

  const handleOnDelete = useCallback(() => onDelete(id), [id, onDelete]);

  const copyUrl = useCallback(() => {
    copyTextToClipboard(url);
    createToast({ content: 'Copied URL to clipboard', category: 'success' });
  }, [url]);

  return (
    <LastUpdatedOrActionButtons
      lastUpdated={lastUpdatedFromNow}
      isFocused={isFocused}
      areSelectedItems={areSelectedItems}
    >
      <Tooltip content={url}>
        <Button
          className={styles.infoButton}
          tabIndex={-1}
          onClick={copyUrl}
        >
          <StrokeIcon />
        </Button>
      </Tooltip>

      <EditButton
        onClick={openEditFeedSubMenu}
      />

      <DeleteButton
        shortcut={deleteShortcut}
        onClick={handleOnDelete}
      />
    </LastUpdatedOrActionButtons>
  );
});

interface FeedSourceItemProps {
  id: string;
  index: number;
  feed: RssFeed & FeedStats;
  views?: FilteredView[];
  associatedViews?: FilteredView[];
  isFocused: boolean;
  isCmdPaletteOpen: boolean;
  setSelectedId: (id: string) => void;
  onDelete: (id: string) => void;
  deleteShortcut: string | string[];
  onCheckedChangeWithShiftInfo: ({ isChecked, isShiftKey, id, index }: { isChecked: boolean; isShiftKey: boolean; id: string; index: number; }) => void;
  isChecked: boolean;
  splitByDefaultValue: string | null;
  areSelectedItems: boolean;
}

const FeedSourceItem = React.memo(function _FeedSourceItem({
  id,
  index,
  feed,
  views,
  associatedViews,
  isFocused,
  isCmdPaletteOpen,
  setSelectedId,
  onDelete,
  deleteShortcut,
  onCheckedChangeWithShiftInfo,
  isChecked,
  splitByDefaultValue,
  areSelectedItems,
}: FeedSourceItemProps) {
  const itemRef = useRef<HTMLTableRowElement>(null);
  const name = feed.name;
  const description = feed.description;
  const imageUrl = feed.image_url;
  const docsCount = feed?.docsCount;
  const lastUpdated = feed?.last_updated;

  const setSelectedIdIfDropdownNotOpen = useCallback(() => {
    if (window.isRadixDropdownOpen || isCmdPaletteOpen) {
      return;
    }

    setSelectedId(id);
  }, [id, isCmdPaletteOpen, setSelectedId]);

  const headerHeight = 109;
  useScrollIntoViewIfNeeded(itemRef, isFocused, headerHeight);

  const linkTo = urlJoin(['/filter', `rssSource:"${id}"`, 'split', SplitByKey.Seen, splitByDefaultValue]);

  const onRowClick = useCallback((e: React.MouseEvent) => {
    if (!onCheckedChangeWithShiftInfo || !areSelectedItems) {
      return;
    }

    onCheckedChangeWithShiftInfo({ isChecked: !isChecked, isShiftKey: e.shiftKey, id, index });
  }, [onCheckedChangeWithShiftInfo, id, index, isChecked, areSelectedItems]);

  const onCheckedChange = useCallback(() => {
    onCheckedChangeWithShiftInfo({ isChecked: !isChecked, isShiftKey: false, id, index });
  }, [onCheckedChangeWithShiftInfo, id, index, isChecked]);

  return (
    <tr
      ref={itemRef}
      className={`${styles.feedItem} ${isFocused ? styles.isFocused : ''} ${isChecked ? styles.isChecked : ''}`}
      onMouseOver={setSelectedIdIfDropdownNotOpen}
      onFocus={setSelectedIdIfDropdownNotOpen}
      onClick={onRowClick}
    >
      <td className={styles.checkboxWrapper}>
        <CustomCheckbox label={`feed-id-${id}`} isChecked={isChecked} onCheckedChange={onCheckedChange} />
      </td>
      <td className={styles.name}>
        <Link to={linkTo} className={styles.nameWithImage}>
          <ImageWithFallback
            imageUrl={imageUrl}
            loading="lazy"
            width="24px"
            height="24px"
            fallbackImage={<FeedFallbackFavicon height="24" width="24" />}
          />
          <span>{name ?? `No name (${feed.url})`}</span>
        </Link>
      </td>
      <td className={styles.description}>
        <Link to={linkTo}>
          <span>{description}</span>
        </Link>
      </td>
      <td className={styles.documents}>
        {docsCount ?? 0}
      </td>
      <td className={styles.views}>
        <FeedViewsDropDown
          feedId={id}
          feed={feed}
          views={views}
          associatedViews={associatedViews}
          isFocused={isFocused}
        />
      </td>
      <td className={styles.lastUpdated}>
        <FeedLastUpdatedOrActionButtons
          id={id}
          lastUpdated={lastUpdated}
          isFocused={isFocused}
          url={feed.url}
          deleteShortcut={deleteShortcut}
          onDelete={onDelete}
          areSelectedItems={areSelectedItems}
        />
      </td>
    </tr>
  );
});

export const FeedsSourceList = React.memo(function FeedsSourceList() {
  const [selectedFeedIds, setSelectedFeedIds, selectedFeedIdsRef] = useStatePlusLiveValueRef<string[]>([]);
  const [deleteFeedDialogOpen, setDeleteFeedDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId, []));
  const sortByKey = globalState(useCallback((state) => state.client.sortFeedsByKey, []));
  const sortOrder = globalState(useCallback((state) => state.client.sortFeedsByOrder, []));
  const isCmdPaletteOpen = globalState(useCallback((state) => state.cmdPalette.isOpen, []));
  const views = useSavedFilteredViews();
  const { feedsWithStats, hasFinishedCounts } = useFeedsStats();
  const viewsByFeedId = useViewsByFeedId();
  const history = useHistory();
  const shortcutsMap = useShortcutsMap();
  const documentLocations = useDocumentLocations();
  const splitByDefaultValue = useMemo(() => getSplitByDefaultValue(SplitByKey.Seen, documentLocations), [documentLocations]);

  // Doing this to prevent changing focus after creating a new view
  const debouncedIsCmdPaletteOpen = useDebounce(isCmdPaletteOpen, 500);
  const debouncedIsCmdPaletteOpenRef = useRef(false);

  useEffect(() => {
    debouncedIsCmdPaletteOpenRef.current = debouncedIsCmdPaletteOpen;
  }, [debouncedIsCmdPaletteOpen]);

  const filterFn = useCallback((feed: RssFeed & {id: string;}) => {
    const matchesName = Boolean(feed.name && feed.name.toLowerCase().includes(searchQuery.toLowerCase()));
    const matchesDescription = Boolean(feed.description && feed.description.toLowerCase().includes(searchQuery.toLowerCase()));
    const feedViews = viewsByFeedId[feed.id];
    const matchesViews = Boolean(feedViews && feedViews.some((view) => view.name.toLowerCase().includes(searchQuery.toLowerCase())));
    return matchesName || matchesDescription || matchesViews;
  }, [searchQuery, viewsByFeedId]);

  const filteredRss = useMemo(() => searchQuery ? feedsWithStats.filter(filterFn) : feedsWithStats, [feedsWithStats, filterFn, searchQuery]);

  const orderedRss = useMemo(() => {
    if (sortByKey === TableSortKey.Documents && !hasFinishedCounts) {
      return filteredRss;
    }

    return orderBy(filteredRss, [(feed) => {
      const id = feed.id;

      switch (sortByKey) {
        case TableSortKey.Name:
          return feed.name?.toLocaleLowerCase() || '';
        case TableSortKey.Description:
          return feed.description?.toLocaleLowerCase() || '';
        case TableSortKey.Documents:
          return feed.docsCount ?? 0;
        case TableSortKey.Views:
          return viewsByFeedId[id]?.length ?? 0;
        case TableSortKey.LastUpdated: {
          const lastUpdated = feed.last_updated;

          if (!lastUpdated && sortOrder === SortOrder.Asc) {
            return Infinity;
          } else {
            return lastUpdated ?? 0;
          }
        }
      }
    }], [sortOrder]);
  }, [filteredRss, sortByKey, sortOrder, viewsByFeedId, hasFinishedCounts]);

  const orderedRssKeys = useMemo(() => orderedRss.map((feed) => feed.id), [orderedRss]);

  const onHeaderCheckedChange = useCallback(() => {
    setSelectedFeedIds((prev) => prev.length ? [] : orderedRssKeys);
  }, [setSelectedFeedIds, orderedRssKeys]);

  const onHeaderClick = useCallback((key: TableSortKey) => {
    if (key === sortByKey) {
      setSortFeedsByOrder(sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
      return;
    }

    setSortFeedsByKey(key);
  }, [sortByKey, sortOrder]);

  useEffect(() => {
    return () => {
      setFocusedFeedId(null);
    };
  }, []);

  useEffect(() => {
    if (focusedFeedId) {
      return;
    }

    if (orderedRssKeys.length > 0 && !window.isRadixDropdownOpen && !debouncedIsCmdPaletteOpenRef.current) {
      setFocusedFeedId(orderedRssKeys[0]);
    }

    setFocusedDocumentId(null, { userInteraction: 'unknown' });
  }, [orderedRssKeys, focusedFeedId]);

  const navItems = useCallback((direction: number) => {
    if (isCmdPaletteOpen) {
      return;
    }

    const currentIndex = orderedRssKeys.findIndex((key) => {
      return key === focusedFeedId;
    });

    if (direction > 0) {
      if (orderedRssKeys[currentIndex + 1]) {
        setFocusedFeedId(orderedRssKeys[currentIndex + 1]);
      }
      return;
    }

    if (orderedRssKeys[currentIndex - 1]) {
      setFocusedFeedId(orderedRssKeys[currentIndex - 1]);
    }
  }, [orderedRssKeys, focusedFeedId, isCmdPaletteOpen]);

  const onCheckedChangeWithShiftInfo = useOnItemChecked({ selectedIds: selectedFeedIdsRef.current, setSelectedIds: setSelectedFeedIds, allIds: orderedRssKeys });

  const onDelete = useCallback((id: string) => {
    setFocusedFeedId(id);
    setDeleteFeedDialogOpen(true);
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    if (!focusedFeedId) {
      return;
    }

    await removeFeed(focusedFeedId, { userInteraction: 'click-feed-sources-list' });
    navItems(1);
    setDeleteFeedDialogOpen(false);
  }, [focusedFeedId, navItems]);

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.Down], useCallback(() => navItems(1), [navItems]));
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.Up], useCallback(() => navItems(-1), [navItems]));

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.DeleteDocument], useCallback(async () => {
    if (focusedFeedId) {
      setDeleteFeedDialogOpen(true);
    }
  }, [focusedFeedId]));

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.Tab], useCallback(() => history.push('/feed/suggestions'), [history]));
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.PreviousTab], useCallback(() => history.push('/feed/suggestions'), [history]));

  const tableHeaders = useMemo(() => [{
    title: 'Name',
    sortkey: TableSortKey.Name,
  }, {
    title: 'Description',
    sortkey: TableSortKey.Description,
  }, {
    title: 'Documents',
    sortkey: TableSortKey.Documents,
    isLoading: !hasFinishedCounts,
  }, {
    title: 'Views',
    sortkey: TableSortKey.Views,
  }, {
    title: 'Last Updated',
    sortkey: TableSortKey.LastUpdated,
  }] as TableHeader[], [hasFinishedCounts]);

  const areSelectedItems = useMemo(() => selectedFeedIds.length > 0, [selectedFeedIds]);

  return <div
    className={`${styles.sources} ${areSelectedItems ? styles.areSelectedItems : ''}`}>
    <Suspense fallback={null}><InboxSidebar /></Suspense>
    <div className={styles.sourcesContainer}>
      <div className={styles.header}>
        <div className={styles.leftNav}>
          <span className={styles.title}>
            Feeds
          </span>
          <ul className={`${styles.navPillsContainer}`}>
            <li>
              <Link to="/feed/sources" className={`${styles.navPill} ${styles.active}`}>
                Subscribed
              </Link>
            </li>
            <li>
              <Link to="/feed/suggestions" className={styles.navPill}>
                Suggested <span className={styles.newLabel}>New</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${styles.headerRight}`}>
          <SearchInput setQuery={setSearchQuery} />
          <Button variant="default" className={styles.primary} onClick={() => openFeedsSubMenu()}>Add feed</Button>
        </div>
      </div>
      <div className={`${styles.listContainer} ${styles.sourcesList} has-visible-scrollbar`}>
        {areSelectedItems &&
          <FeedsBulkActionsHeader
            isChecked
            onCheckedChange={onHeaderCheckedChange}
            selectedFeedIds={selectedFeedIds}
            setSelectedFeedIds={setSelectedFeedIds}
            areAllItemsSelected={selectedFeedIds.length === orderedRssKeys.length}
            views={views}
            viewsByFeedId={viewsByFeedId}
          />
        }

        <table>
          {!areSelectedItems && <Table.Header
            onCheckedChange={onHeaderCheckedChange}
            onHeaderClick={onHeaderClick}
            headers={tableHeaders}
            currentSortKey={sortByKey}
            currentSortOder={sortOrder}
            coverBorder={false}
            />
          }

          <tbody>
            {orderedRss.map((feedWithStats, index) => {
              const id = feedWithStats.id;
              const associatedViews = viewsByFeedId[id];

              return (
                <FeedSourceItem
                  key={id}
                  index={index}
                  id={id}
                  feed={feedWithStats}
                  views={views}
                  associatedViews={associatedViews}
                  isFocused={focusedFeedId === id}
                  isCmdPaletteOpen={isCmdPaletteOpen}
                  setSelectedId={setFocusedFeedId}
                  onDelete={onDelete}
                  deleteShortcut={shortcutsMap[ShortcutId.DeleteDocument]}
                  isChecked={selectedFeedIds.includes(id)}
                  onCheckedChangeWithShiftInfo={onCheckedChangeWithShiftInfo}
                  splitByDefaultValue={splitByDefaultValue}
                  areSelectedItems={areSelectedItems}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {Boolean(filteredRss.length) && <FloatingPill><>Count: {filteredRss.length.toLocaleString()}</></FloatingPill>}

      <DeleteFeedDialog
        isOpen={deleteFeedDialogOpen}
        onConfirm={onDeleteConfirm}
        onCancel={() => setDeleteFeedDialogOpen(false)} />
    </div>
  </div>;
});
